import VueRouter from 'vue-router'
import Vue from "vue";
Vue.use(VueRouter);
export default new VueRouter({
    mode: 'hash',
    routes: [{
        name: 'IndexPage',
        path: '/',
        component: () => import('./views/Index.vue')
    },{
        name: 'SubmitPage',
        path: '/submit',
        component: () => import('./views/Submit.vue')
    },{
        name: 'Activitydetails',
        path: '/activitydetails',
        component: () => import('./views/Activitydetails.vue')
    },{
        name: 'index',
        path: '/login',
        component: () => import('./views/Index.vue')
    }]
})
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "@/assets/fonts/fenshu.css";
export default {
  name: "App"
};
</script>

<style lang="scss">
#app {
  font-family: PingFangSC-Regular, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // padding: 0 2.1875rem;

  // @media screen and(min-width: 1920px) {
  //   padding: 0 8.1875rem;
  // }
}
.din_score {
  font-family: "DIN Condensed";
}
.modal-dialog-scrollable .modal-body{
  overflow-x: hidden;
}
</style>
